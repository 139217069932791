@use "../../styles/veriables" as v;

.image-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-modal-content {
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  width: 90%;
  max-width: 800px;
  max-height: 80vh;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-image: linear-gradient(45deg, v.$color1, v.$color2, v.$color1);
}

.modal-image {
  width: 100%;
  height: auto;
  max-height: 100%;
  border-radius: 10px;
  object-fit: contain;
}

/* Media Queries for tablets and phones */
@media (min-width: 768px) {
  .image-modal-content {
    width: 80%;
    max-height: 70vh;
  }
}

@media (min-width: 1024px) {
  .image-modal-content {
    width: 50%;
    max-height: 70vh;
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #000;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  border-radius: 50%;
  padding: 3px 8px;
  cursor: pointer;
}

.close-button:hover {
  color: #ddd;
}

.img-fluid{
    height: 500px !important;
    width: 400px !important;
    object-fit: cover;
    
}




