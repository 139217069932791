$color1: rgb(221, 172, 67);
/* $color1: rgb(249, 213, 67); */
$color2: rgb(0, 0, 0);
$color3: rgb(201, 198, 198);
$color1-opacity-50: rgba(249, 212, 67, 0.5);
$color2-opacity-50: rgb(56, 49, 38, 0.5);
$color2-opacity-70: rgb(56, 49, 38, 0.7);
$margin-right: 0;


$primary: $color1;
$secondary: $color1;
$font-family-sans-serif: 'Playfair Display', serif;
$btn-close-color: $color1;
$navbar-brand-margin-end: $margin-right;
$carousel-indicator-height: 0;