

.welcome {
    position: relative;
    overflow: hidden; /* Hide the content that overflows the container */
  }
  
  .background-logo {
    background-image: url('../../../public/images/logo/logo.jpg'); /* Path to your logo image */
    background-size: cover; /* Ensure the background image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the background image */
    position: fixed; /* Fix the background image */
    width: 75%; /* Arka plan logosunun genişliği (ekrana göre ayarlayabilirsiniz) */
    max-width: 750px; /* Maksimum genişlik belirleyin (istediğiniz büyüklüğe ayarlayabilirsiniz) */
    top: 6%;
    left: 30%; /* Soldan 50% mesafede */
    height: 100vh; /* Set the height to 100% of the viewport height */
    z-index: -1; /* Place the background behind other content */
    opacity: 0.3;

    @media (max-width:768px) {
      left: 10%
    }
  }