@use "../../styles/veriables" as v;

.upcoming-events {
    h2 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
      padding: 20px;
      border: 5px solid v.$color1;
      border-radius: 40px;

      .baslik{
        color: v.$color3;
        font-weight: 700;
      
      }
      
    }
  
    .swiper-slide {
      margin-top: 50px;
      height: auto;
      cursor: pointer;
    }
  }