@mixin transform($t) {
    transform: $t;
    -webkit-transform: $t;
    -moz-transform: $t;
    -ms-transform: $t;
    -o-transform: $t;
  }
  
  @mixin transition($t) {
    transition: $t;
    -webkit-transition: $t;
    -moz-transition: $t;
    -ms-transition: $t;
    -o-transition: $t;
  }
  
  @mixin borderRadius($t) {
    border-radius: $t;
    -webkit-border-radius: $t;
    -moz-border-radius: $t;
    -ms-border-radius: $t;
    -o-border-radius: $t;
  }
  