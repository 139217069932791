@use "../../styles/veriables" as v;
@use "../../styles/mixins" as m;

.quality {
    height: 100%;
    

    .card {
    
        box-shadow: 0 8px 10px 4px v.$color2;
        border: none;
        z-index: 1;
        
       
        @include m.transform(translateY(-150px));
        margin-top: 10px !important;
        cursor: pointer;
        
        

        .title {
            background-color: v.$color1;
            color: v.$color2;
            height: 47px;
            padding-top: 8px;
            margin-top: 40px;
            margin-bottom: 0;
            

            
           
        }
        .card-body{
            background-color: v.$color1;
            
        }

        .card-subtitle {
            font-weight: bold;
            
        }
    
        .card-img{
            background-color: v.$color2;
            overflow: hidden;
            @include m.transition(transform 0.4s);
        &:hover {
          @include m.transform(scale(1.1));
          overflow: hidden;
        }
        }

        
    }

    

    
    
}