



.galeri {
    padding-top: 50px;


    .photo{
       
        
        @media (max-width: 768px) {
            margin-bottom: 20px; // Dikeyde boşluk eklemek için
    margin-right: 20px; // Yatayda boşluk eklemek için
        }
    }
    


    .kategori{
        
        @media (max-width: 768px) {
            text-align: center;
        }
    }

    .foto {
        height: 200px; 
        @media (max-width: 768px) {
            text-align: center;
            
        }
    }

    .foto img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

    .videolar {
        width: 400px ;
        @media (max-width: 768px) {
            text-align: center;
            width: 50%;
        }
    }
    .videos-baslik{
        margin-top: 30px;
        @media (max-width: 768px) {
            margin-top: 80px;
        }
    }

 
   
}