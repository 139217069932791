@use "../../styles/veriables" as v;
@use "../../styles/mixins" as m;

.event-card {
  height: 550px !important;
  
  @media(max-width: 768px) {
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    color: v.$color3;
    background-color: v.$color2;

    


    .card-subtitle {
      margin-top: 20px;
      margin-bottom: 20px;
      color: v.$color3;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      
    }

    .card-title {
        overflow: hidden !important;
        font-size: 13px;
        text-align: center;
        line-height: 1.3rem;

    }

    

    .image {

      position: relative;
      width: 100%;
      height: 200px !important; /* Görüntülerin varsayılan yüksekliği */
     
      img {
        @media(max-width: 768px) {
          width: 75%;
          text-align: center;
          align-items: center;
          justify-content: center;
        }
        
      
        @include m.transition(transform 0.5s);
        &:hover {
          @include m.transform(scale(1.1));
        }
    
      }
    }
  }
}