.qr {
  display: flex;
  align-items: center;
  justify-content: center;
}

b {
  font-size: 50px;
}

.instagram {
  font-size: 200px;
  margin-bottom: 40px;
}
