.btn-scroll {
    position: fixed;
    bottom: 20px;
    right: 20px;
    opacity: 0;
    z-index: 300;
    &.visible {
      opacity: 1;
    }
    width:50px;
    height: 50px;
  
    display: flex;
    justify-content: center;
    align-items: center;
  
    box-shadow: 0 3px 10px -2px rgb(63, 63, 63);
  
  
    border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -ms-border-radius: 50% !important;
    -o-border-radius: 50% !important;
    transition: opacity 1s ease-in-out !important;
    -webkit-transition: opacity 1s ease-in-out !important;
    -moz-transition: opacity 1s ease-in-out !important;
    -ms-transition: opacity 1s ease-in-out !important;
    -o-transition: opacity 1s ease-in-out !important;
    
  }
  