@use "../../styles/veriables" as v;


.tabs {


  .image {
    position: relative;
  }

  @media (min-width: 992px) {
    .image {
      margin-left: -100px; /* İçeriği sola kaydır */
    }

    .baslik-title {
      margin-left: 180px; /* Başlıkları daha fazla sola kaydır */
    }
  }

  .image img {
    margin: 0 auto;
    width: 360px; /* veya istediğiniz genişlik */
    height: 350px; /* veya istediğiniz yükseklik */
    object-fit: cover; /* Resmi belirtilen boyutlara sığdırır ve kırpar */
    @media (min-width: 768px) {
     
        width: 300px !important; /* Tablet ekranlarda istediğiniz genişlik */
        height: 270px; /* Tablet ekranlarda istediğiniz yükseklik */
    
    }
    
    
  }
  
  
  
  .image h4 {
    position: absolute;
    top: 10px; /* Fotoğrafın üst kısmına hizalama */
    left: 50%; /* Fotoğrafın ortasına hizalama */
    transform: translateX(-50%); /* Yatayda merkezleme */
    background-color: rgba(176, 132, 50, 0.7); /* Başlık arka plan rengi */
    padding: 5px 10px; /* Başlık içeriği ile arka plan arasında boşluk */
    border-radius: 5px; /* Kenar yuvarlatma */
  }
    h5{
      text-align: center;
      margin-top: 150px;
      
     
    }
  
    .baslik-title{

     
      
      @media (min-width: 768px) {
        margin-left: 130px;
      }
    }
  

    h4 {
        color: v.$color3;
        font-size: 1.7rem;
        font-weight: 900;
        text-align: center;
        margin-bottom: 50px;

       
      }
/* 
      ul{
        color: v.$color3;
        font-size: 1.4rem;
        font-weight: 500;
       
      } */

      @media (max-width: 768px) {

        .icons {
          display: none;
        }

        h5{
            
          margin-top: 10px;
          margin-bottom: 100px;
          }
        .mobil-ekran {
          flex-direction: column;
          
          .image {
            
            flex-direction: column;

            
            .row {
              flex-direction: column;
              
              
            }
          }
        }
      }

     
}