@use "../../styles/veriables" as v;

.welcome {
  h2 {
    color: v.$color3;
    font-size: 2.2rem;
    font-weight: 800;
  }

  h5 {
    color: v.$color3;
    font-weight: 700;
    font-size: 1.4rem;
  }

  ul li {
    margin-bottom: 15px;
  }
  p {
    font-size: 13px;
    color: v.$color3;
  }
}
