@use "../../styles/_veriables.scss" as v;
footer {
  background-color: v.$color2;
  color: v.$color1;
  padding-top: 100px;
  padding-bottom: 100px;
  
  

  p{
    color: v.$color3;
  }

  
}
