.foto {
    display: flex;
    flex-wrap: wrap; // Yatayda sığmayan fotoğrafları alta almak için kullanılır
    justify-content: space-between; // Yatayda eşit aralıklarla sıralamak için
    align-items: center; // Dikeyde ortalamak için
  
  }
  
  .foto img {
    margin-bottom: 20px; // Dikeyde boşluk eklemek için
    margin-right: 20px; // Yatayda boşluk eklemek için
    width: 350px; /* veya istediğiniz sabit genişlik */
    height: 250px; /* veya istediğiniz sabit yükseklik */
    object-fit: cover; /* resimleri boyutlarına göre kes */

    

   
  }