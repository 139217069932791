.article-table {
    width: 100%;
    border-collapse: collapse;
  
    th, td {
      padding: 12px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }
  
    th {
      color: #f2f2f2;
      font-weight: bold;
    }
  
    td {
      &:last-child {
        text-align: center;
      }
    }
  
   /*  tr:nth-child(even) {
      background-color: #f2f2f2;
    } */
  
    @media (max-width: 768px) {
      td, th {
        padding: 8px;
      }
    }
  }
  
  .article-form {
    margin-top: 20px;
  
    input {
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 14px;
  
      &:last-child {
        margin-right: 0;
      }
    }
  
    button {
      padding: 10px 20px;
      background-color: #ccac2d;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }