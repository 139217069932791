

.events {
    h2 {
        border: none;
        display: flex;
      justify-content: space-between;
   
      padding: 20px 0px;
        
    }
    .swiper-slide {
        margin-top: 50px;
        height: auto;
        cursor: pointer;
      }
}