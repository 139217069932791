@use "../../styles/veriables" as v;
@use "../../styles/mixins" as m;


.navbar {
    color: v.$color1 !important;



    .menubar {
        background-color: v.$color2;
        
    }

   
   
    
}



